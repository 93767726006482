<template>
	<div v-if="booted" class="row">
		<!-- Si la limite a été atteinte -->
		<RegularBuyPlan v-if="!has_right" @purchased="hideRegularPurchase" />

		<!-- En offline, on authorise l'ajout manuel -->
		<div v-else-if="!isOnline" class="col-12 text-center">
			<button @click="openManuelCreation" class="add_horse btn btn-primary rounded-pill">{{ $t('global.actually_offline') }} <font-awesome-icon :icon="['fas', 'wifi-slash']" /></button>
		</div>
		<template v-else>
			<div class="col-12 col-lg">
				<div class="input-group input-group-horse-filter">
	                <div class="input-group-prepend">
	                	<e-select
							track-by="code"
							label="label"
							:options="all_types_search"
							:allow-empty="false"
							:show-labels="false"
							v-model="type_search"
						/>
	                </div>
	                <input type="text" class="form-control" v-model="search_value" @keydown.enter.prevent="search" required :placeholder="$t('horse.placeholder_'+type_search.code)">
	            </div>
			</div>
			<div class="mt-2 mt-lg-0 col-md-6 col-lg-auto">
				<b-button class="h-100" variant="secondary" block @click="openFilters">{{ $t('horse.add_filters') }} <font-awesome-icon :icon="['fal', 'plus-circle']" class="ml-2" /></b-button>
			</div>
			<div class="mt-2 mt-lg-0 col-md-6 col-lg-auto">
				<b-button class="h-100" variant="primary" block @click="search">{{ $t('global.rechercher') }} <font-awesome-icon v-if="searching" :icon="['fas', 'spinner']" pulse /><font-awesome-icon v-else :icon="['fal', 'search']" class="ml-2" /></b-button>
			</div>
			<div class="col-12 d-flex">
				<div v-for="(filter, index) in filters_saved" :key="index">
					<b-button v-if="is_not_empty(filter)" @click="deleteFilter(index)" variant="primary" rounded pill size="sm" class="mt-1 mr-2 mb-3">
						{{ $t('horse.filter.'+index) }} : 
						<template v-if="index == 'race'">
							{{ race_formatted }}
						</template>
						<template v-else-if="index == 'sexe'">
							{{ filter.sexe_label }}
						</template>
						<template v-else-if="index == 'alive'">
							{{ $t('global.oui') }}
						</template>
						<template v-else>
							{{ filter }}
						</template>
						<font-awesome-icon :icon="['fal', 'times']" />
					</b-button>
				</div>
			</div>
			<div v-if="code_erreur !== ''" class="col-12">
				<b-alert variant="danger" show>{{ error_message_trad }}</b-alert>
			</div>
			<LoadingSpinner v-if="processing" class="col-12"/>
			<template v-else-if="type_search && type_search.code == 'ifce'">
				<div v-if="code_erreur === 'TMR' || horses.length == 20" class="col-12 mt-2">
					<b-alert variant="warning" show>{{ $t("global.message_affine") }}</b-alert>
				</div>
				<div v-if="horses.length > 0" class="col-12 mt-2">
					<div class="mt-4 mb-3">{{ horses.length }} {{ $t('table.general.resultats') }} :</div>
					<div v-for="horse in horses" :key="horse.references.idPub" class="result_horse">
						<div class="row align-items-center">
							<div class="col">
								<h5 class="mb-0">{{ horse.nom }}</h5>
								{{ horse.codeRace }}, {{ horse.codeSexe }}, {{ horse.codeRobe }}, {{ horse.yearNaissance }}<br>
								{{ $t('horse.par') }} {{ horse.genealogy.sireName }} {{ horse.genealogy.sireBreed }} {{ $t('horse.et') }} {{ horse.genealogy.damName }} {{ horse.genealogy.damBreed }}<br>
							</div>
							<div class="col-auto">
								<b-button variant="secondary" block @click="addHorseSire(horse)">{{ $t('global.ajouter') }}</b-button>
							</div>
						</div>
					</div>
				</div>
			</template>
			<template v-else-if="type_search && type_search.code == 'weatherbys'">
				<div v-if="horses.length > 0" class="col-12 mt-2" >
					{{ horses.length }} {{ $t('table.general.resultats') }} :
					<div v-for="horse in horses" :key="horse.id" class="result_horse">
						<div class="row align-items-center">
							<div class="col">
								<h5 class="mb-0">{{ horse.nom }}</h5>
								{{ horse.codeRace }}, {{ horse.codeSexe }}, {{ horse.codeRobe }}<br>
								{{ $t('horse.par') }} {{ horse.genealogy.sireName }} {{ $t('horse.et') }} {{ horse.genealogy.damName }}<br>
							</div>
							<div class="col-auto">
								<b-button variant="secondary" block @click="addHorseWeatherbys(horse)">{{ $t('global.ajouter') }}</b-button>
							</div>
						</div>
					</div>
				</div>
			</template>
		</template>

		<b-modal ref="filters" hide-footer>
            <template v-slot:modal-title>
            	{{ $t("horse.add_filters") }}
	        </template>
	        <div v-if="type_search && type_search.code == 'ifce'" class="row">
	        	<div class="col-12 mt-1">
					<input type="number" name="date_min" class="form-control" v-model="filters_ifce.date_min" :placeholder="getTrad('horse.filter.date_min')" />
				</div>
				<div class="col-12 mt-1">
					<input type="number" name="date_max" class="form-control" v-model="filters_ifce.date_max" :placeholder="getTrad('horse.filter.date_max')"/>	
				</div>
				<div class="col-12 mt-1">
					<input type="string" name="mother_name" class="form-control" v-model="filters_ifce.mother_name" :placeholder="getTrad('horse.filter.mother_name')"/>
				</div>
				<div class="col-12 mt-1">
					<input type="string" name="father_name" class="form-control" v-model="filters_ifce.father_name" :placeholder="getTrad('horse.filter.father_name')"/>
				</div>
				<div class="col-12 mt-1">
		        	<e-select
						id="race_code"
						v-model="filters_ifce.race"
						track-by="race_code"
						label="race_display"
						:placeholder="$t('horse.filter.race')"
						:options="horse_races"
						:searchable="true"
						:allow-empty="true"
						:show-labels="false"
						:multiple="true"
					/>
				</div>
				<div class="col-12 mt-1">
					<e-select
						id="sexe_code"
						v-model="filters_ifce.sexe"
						track-by="sexe_code"
						label="sexe_label"
						:placeholder="$t('horse.filter.sexe')"
						:options="horse_sexes"
						:searchable="true"
						:allow-empty="true"
						:show-labels="false"
					/>
				</div>
				<div class="col-12 mt-1">
					<b-form-checkbox class="mr-2" v-model="filters_ifce.alive">{{ $t('horse.filter.alive') }}</b-form-checkbox>
				</div>
				<div class="col-12 mt-1 text-center">
					<b-button primary="secondary" @click="saveFilters"><font-awesome-icon :icon="['fal', 'save']" class="ml-2" /> {{ $t('global.sauvegarder_filtrer') }}</b-button>
				</div>
	        </div>
	    </b-modal>
	</div>
</template>

<script type="text/javascript">
	import Horse from "@/mixins/Horse.js"
    import _deepClone from 'lodash/cloneDeep'
    import _isEmpty from 'lodash/isEmpty'

	export default {
		name: 'AddHorse',
		mixins: [Horse],
		props: ['from'],
		data () {
			return {
				search_value: '',
				all_types_search: [],
				type_search: null,
				has_right: false,
				code_erreur: '',
				booted: false,
				searching: false,
				processing: false,
				limit_horse: 0,
				horses: [],
				filters_ifce: {
					date_min: null,
					date_max: null,
					mother_name: null,
					father_name: null,
					race: [],
					sexe: null
				},
				filters_saved: {},
				horse_sexes: [],
				horse_races: [],
				horse_robes: []
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				this.all_types_search = []
	            this.mask_ifce = this.getConfigIfce()
	            this.mask_weatherbys = this.getConfigWeatherbys()

	            if(!this.mask_ifce) {
	            	this.all_types_search.push({
	            		code: 'ifce',
	            		label: 'IFCE'
	            	})
	            }

	            if(!this.mask_weatherbys) {
	            	this.all_types_search.push({
	            		code: 'weatherbys',
	            		label: 'Weatherbys'
	            	})
	            }

	            this.type_search = this.all_types_search[0]

				this.limit_horse = this.getUserHorseLimit()
				this.has_right = await this.checkCanAdd()

				if(!this.has_right) {
					this.code_erreur = "NR"
				}

				this.horse_sexes = await this.getSexes()
				this.horse_sexes.push({
					sexe_code: 'MH',
					sexe_label: this.getTrad('horse.sex.MH')
				})
                this.horse_races = await this.getRaces()
                this.horse_robes = await this.getRobes()

				this.booted = true
			},

			openFilters() {
				this.$refs.filters.show()
			},

			search() {
				this.searching = true
				this.horses = []
				const input = this.clean_input(this.search_value)
				this['search_'+this.type_search.code](input)
			},

			async search_ifce(input) {
				// On commence par tester les regex d'erreurs possibles
				this.code_erreur = this.checkPotentialError(input)
				if(this.code_erreur == '') {
					let horse_id = null
					let exists = false
					if(this.regex_sire.test(input)) {
						const horse = await this.getHorseBySire(parseInt(input).toString())
						if(horse) {
							horse_id = horse.horse_id
							exists = true
						}
						else {
							const data = await this.addHorse(input, 'sire')
							horse_id = data.horse_id
							exists = !data.new
						}
					}
					else if(this.regex_microship.test(input)) {
						const horse = await this.getHorseByTranspondeur(input)
						if(horse) {
							horse_id = horse.horse_id
							exists = true
						}
						else {
							const data = await this.addHorse(input, 'microship')
							horse_id = data.horse_id
							exists = !data.new
						}
					}
					else if(this.regex_ueln.test(input)) {
						const horse = await this.getHorseByUeln(input)
						if(horse) {
							horse_id = horse.horse_id
							exists = true
						}
						else {
							const data = await this.addHorse(input, 'ueln')
							horse_id = data.horse_id
							exists = !data.new
						}
					}
					else if(this.regex_name_like.test(input)) {
						let filters = _deepClone(this.filters_saved)
						if(!_isEmpty(filters)) {
							if(filters.race && (filters.race.length > 1 || filters.race.length == 0)) {
								filters.race = null
							}
							else if(filters.race && filters.race.length == 1) {
								filters.race = filters.race[0]
							}

							if(filters.sexe && filters.sexe.sexe_code == 'MH') {
								filters.sexe = null
							}
						}

						const data = await this.fetchHorseByNameIfce(input, filters)
						if(data.code == 0) {
							let horses = data.result
							if(!_isEmpty(this.filters_saved)) {
								if(this.filters_saved.alive) {
									horses = horses.filter(horse => !horse.dateMort)
								}

								if(this.filters_saved.race && this.filters_saved.race.length > 1) {
									const races_code = this.filters_saved.race.map(race => race.race_code)
									horses = horses.filter(horse => races_code.includes(horse.codeRace))
								}

								if(this.filters_saved.sexe && this.filters_saved.sexe.sexe_code == 'MH') {
									horses = horses.filter(horse => horse.codeSexe == 'M' || horse.codeSexe == 'H')
								}
							}
							this.horses = horses.map(horse => {
								let year = ''
								if(horse.dateNaissance) {
									const naissance = new Date(horse.dateNaissance)
									year = naissance.getFullYear()
								}
								let race = this.horse_races.find(race => race.race_code == horse.codeRace)
								race = race ? race.race_label : horse.codeRace

								let sexe = this.horse_sexes.find(sexe => sexe.sexe_code == horse.codeSexe)
								sexe = sexe ? sexe.sexe_label : horse.codeSexe

								let robe = this.horse_robes.find(robe => robe.robe_code == horse.codeRobe)
								robe = robe ? robe.robe_label : horse.codeRobe

								return {...horse, yearNaissance: year, codeRace: race, codeSexe: sexe, codeRobe: robe}
							})
						}
						else {
							this.code_erreur = data.code
						}
					}
					if(exists && horse_id) {
						this.infoToast("toast.horse_already_exists")
						this.$router.push({ name: 'HorseFicheInfo', params: {horse_id} })
					}
					else if(horse_id) {
						this.next(horse_id)
					}
				}
				this.searching = false
			},

			async search_weatherbys(input) {
				const data = await this.fetchHorseByNameWeatherbys(input)
				if(data.code == 0) {
					this.horses = data.result.map(horse => {
						let year = ''
						if(horse.dateNaissance) {
							const naissance = new Date(horse.dateNaissance)
							year = naissance.getFullYear()
						}
						return {...horse, yearNaissance: year}
					})
				}
				else {
					this.code_erreur = data.code
				}
				this.searching = false
			},

			async addHorseSire(item) {
				this.processing = true
				const data = await this.addHorse(item.references.idPub, 'id_pub')
				if(data.new) {
					this.next(data.horse_id)
				}
				else {
					this.infoToast("toast.horse_already_exists")
					this.$router.push({ name: 'HorseFicheInfo', params: {horse_id: data.horse_id} })
				}
			},

			async addHorseWeatherbys(item) {
				this.processing = true
				const response = await this.addHorseWeatherbysID(item.id)

				if(response.code_retour === 0 && !this.from) {
					this.next(response.retour.horse_id)
				}
				else {
					this.failureToast(this.getTrad("horse.already_exists"), true)
				}
			},

			async next(horse_id) {
				await this.$sync.force()
				this.processing = false
				if(this.from == 'tiers') {
					this.$emit('update:horse_id', horse_id)
				}
				else if(this.getConfig('skip_residence_onboarding')) {
					this.$router.push({ name: 'horseListe' })
				}
				else {
					this.$router.push({ name: 'mouvementAjout', params: { horse_id: horse_id, from: this.$options.name, type: 'entree' }})
				}
			},

			hideRegularPurchase() {
				this.code_erreur = ''
				this.has_right = true
			},

			openManuelCreation() {
                this.$router.push({name: 'AddManuelBeta'})
			},

			clean_input: function(input) {
				if(!input) return input
				input = input.toUpperCase()
				input = input.replace(/[\u2018\u2019]/g, "'")
				input = input.replace(new RegExp(/[èéêë]/g),"e")
				return input
			},

			saveFilters() {
				this.filters_saved = _deepClone(this.filters_ifce)
				this.$refs.filters.hide()
				this.search()
			},

			deleteFilter(index) {
				this.filters_saved[index] = null
				this.filters_ifce[index] = null
			},

			is_not_empty(filter) {
				return filter === true || typeof filter === 'number' || !_isEmpty(filter)
			}
		},

		computed: {
			isOnline() {
				return window.navigator.onLine
			},
			error_message_trad() {
				if(['IK','IF','NOR'].indexOf(this.code_erreur) > -1) {
					return this.getTrad("horse.introuvable")
				}
				else if(this.code_erreur == 'NR') {
					return this.getTrad("error.NR", [this.limit_horse])
				}
				return this.getTrad("error."+(this.code_erreur == "" ? "UKE" : this.code_erreur))
			},
			race_formatted() {
				return this.filters_saved.race.map(race => race.race_label).join(' / ')
			}
		},

		components: {
			RegularBuyPlan: () => import('@/components/Plans/BuyPlan'),
			LoadingSpinner : () => import('GroomyRoot/components/Logos/LoadingSpinner_35')
		}
	}

</script>